import React, { useState, useEffect } from "react";
import bin from "../images/bin.png";
import uuid from "react-uuid";
import { addData, UpdateData, getItemsbyType, getData, getData_Any2Column } from "../service/APIService";

const UserRegister = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [loadingUserId, setLoadingUserId] = useState("");
  const typeParam = "attendance-user";

  return <div className="container"></div>;
};

export default UserRegister;
