import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { v4 as uuidv4 } from "uuid"; // Import UUID for unique IDs
import { getData, deleteData } from "../service/APIService"; // Import API Service

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const WeightTracker = () => {
  const [weight, setWeight] = useState("");
  const [date, setDate] = useState("");
  const [weightList, setWeightList] = useState([]); // Store weight data
  const [unit, setUnit] = useState("kg");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [userEmail, setUserEmail] = useState(""); // Store logged-in user's email

  // **Check if user is logged in**
  useEffect(() => {
    const sessionUser = JSON.parse(localStorage.getItem("loggedInUser")); // Get logged-in user
    setUserEmail(sessionUser?.email || "guest");

    // Set local date
    const today = new Date();
    const localDate = new Date(today.getTime() - today.getTimezoneOffset() * 60000).toISOString().split("T")[0];
    setDate(localDate);

    // Fetch weight data
    if (sessionUser) {
      fetchDataFromDB(sessionUser.email); // Fetch from DB if logged in
    } else {
      const storedWeightList = JSON.parse(localStorage.getItem("weightList")) || [];
      if (Array.isArray(storedWeightList)) {
        setWeightList(storedWeightList);
      } else {
        setWeightList([]); // Fallback to an empty array to avoid iteration errors
      }
    }
  }, []);

  // **Fetch Data from DB**
  const fetchDataFromDB = async (email) => {
    try {
      const response = await getData(email, "weightlist");
      if (response && response.length > 0) {
        setWeightList(response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // **Delete Data (Guest: Local, Logged-in: DB)**
  const handleDeleteWeight = async (id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete?");
    if (!isConfirmed) return;

    const updatedWeightList = weightList.filter((entry) => entry.id !== id);
    setWeightList(updatedWeightList);

    if (userEmail === "guest") {
      localStorage.setItem("weightList", JSON.stringify(updatedWeightList)); // Store in local storage
    } else {
      try {
        await deleteData(id); // Delete from DB
      } catch (error) {
        console.error("Error deleting data:", error);
      }
    }
  };

  // **Copy to Excel**
  const handleCopyToExcel = () => {
    if (weightList.length === 0) {
      alert("No data available to copy.");
      return;
    }
    const excelData = "Date\tWeight\n" + weightList.map((entry) => `${entry.date}\t${entry.weight} ${unit}`).join("\n");
    navigator.clipboard.writeText(excelData).then(() => alert("Data copied to clipboard in Excel format!"));
  };

  // **Sort Entries (Table: Descending)**
  const sortedWeightList = [...weightList].sort((a, b) => new Date(b.date) - new Date(a.date));

  // **Pagination Logic**
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedWeightList.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(sortedWeightList.length / itemsPerPage);

  // **Format Date (MMM-DD)**
  const formatDate = (dateString) => {
    const date = new Date(`${dateString}T00:00:00`);
    return new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
      month: "short",
    }).format(date);
  };

  return (
    <div className="container">
      <h5 className="text-center">Weight Tracker</h5>
      <div align="center">
        <small>Note: Data is stored locally for guests, and in the database for logged-in users.</small>
      </div>

      {/* Chart Section */}
      {weightList.length > 0 ? (
        <>
          {/* Data Table */}
          <table className="table table-bordered mt-4">
            <thead>
              <tr align="center">
                <th>Date</th>
                <th>Weight</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody align="center">
              {currentItems.map((entry) => (
                <tr key={entry.id}>
                  <td className="p-1">{formatDate(entry.date)}</td>
                  <td className="p-1">
                    {entry.weight} {unit}
                  </td>
                  <td className="p-1">
                    <button className="btn btn-danger btn-sm" onClick={() => handleDeleteWeight(entry.id)}>
                      <i className="fi fi-rr-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="d-flex justify-content-between align-items-center mt-3">
            <button className="btn btn-secondary p-1 pb-0" onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
              <i className="fi-rr-arrow-left"></i>
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button className="btn btn-secondary p-1 pb-0" onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
              <i className="fi-rr-arrow-right"></i>
            </button>
          </div>

          {/* Copy to Excel Button */}
          <div className="d-flex justify-content-end mt-3">
            <button className="btn btn-warning" onClick={handleCopyToExcel}>
              Copy All Data as Excel
            </button>
          </div>
        </>
      ) : (
        <p className="text-center mt-4">No weight data recorded yet.</p>
      )}
    </div>
  );
};

export default WeightTracker;
